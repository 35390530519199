export class Tab {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      const getIndex = (element) => {
        if (!element) {
          return -1
        }
        let currentElement = element
        let index = 0
        while (currentElement.previousElementSibling) {
          index++
          currentElement = currentElement.previousElementSibling
        }
        return index
      }

      const tabs = document.querySelectorAll('.js-tabs')
      tabs.forEach((elm) => {
        const trgs = elm.querySelectorAll('.js-tab_trg')
        const body = document.querySelector('.js-tab_body')
        trgs.forEach((trg) => {
          trg.addEventListener(
            'click',
            (e) => {
              const index = getIndex(e.currentTarget)
              for (let i = 0; i < body.children.length; i++) {
                const bodyElm = body.children[i]
                bodyElm.classList.remove('is-show')
              }
              trgs.forEach((elm) => {
                elm.classList.remove('is-act')
              })
              trg.classList.add('is-act')
              body.children[index].classList.add('is-show')
            },
            false
          )
        })
      })
    })
  }
}
