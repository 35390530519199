'use strict'

// import { IeModal } from './lib/IeModal'
// import { CookiePolicy } from './lib/CookiePolicy'
import { Svg } from './lib/Svg'
import { TelGrant } from './lib/TelGrant'
import { Smooth } from './lib/Smooth'
import { Drawer } from './lib/Drawer'
import { ScrollStatus } from './lib/ScrollStatus'
import { ScrollAnimation } from './lib/ScrollAnimation'
import { KvSlider } from './lib/KvSlider'
import { IndexBrandSlider } from './lib/IndexBrandSlider'
import { GetJson } from './lib/GetJson'
import { Tab } from './lib/Tab'
import { TextSplit } from './lib/TextSplit'
import { FixedSideMenu } from './lib/FixedSideMenu'
import { RightOver } from './lib/RightOver'
import { BusinessSlider } from './lib/BusinessSlider'
import { WorksSlider } from './lib/WorksSlider'
import { Megamenu } from './lib/Megamenu'

// new IeModal('サイト名', 'http://hoge.com')
// new CookiePolicy('optout')
new Svg()
new TelGrant()
new Smooth()
new Drawer()
new ScrollStatus()
new ScrollAnimation()
new KvSlider()
new IndexBrandSlider()
new GetJson()
new Tab()
new TextSplit()
new FixedSideMenu()
new RightOver()
new BusinessSlider()
new WorksSlider()
new Megamenu()
