export class Megamenu {
  constructor() {
    const trgs = document.querySelectorAll('.js-mega_trg')
    const header = document.querySelector('#js-header')
    if (trgs.length == 0) return

    const move = (e) => {
      const hH = header.clientHeight
      const target = e.currentTarget
      const type = e.type
      const mega = target.querySelector('.js-megamenu')
      if (type === 'mouseenter') {
        mega.style.top = hH + 'px'
        mega.classList.add('is-act')
      } else {
        mega.classList.remove('is-act')
      }
    }
    trgs.forEach((data) => {
      data.addEventListener('mouseenter', (e) => {
        move(e)
      })
      data.addEventListener('mouseleave', (e) => {
        move(e)
      })
    })
  }
}
