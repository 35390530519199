import Swiper from 'swiper/bundle'
import 'swiper/css/bundle'

export class IndexBrandSlider {
  constructor() {
    const swiper = new Swiper('.js-brand_slider', {
      pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
      },
      slidesPerView: 1.5,
      spaceBetween: 20,
      autoHeight: true,
      breakpoints: {
        768: {
          slidesPerView: 3.5,
        },
      },
    })
  }
}
