export class KvSlider {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      let flg = 0
      let setAutoTimer
      const copyEn = document.querySelector('.js-kv_copy_en')
      const copyJa = document.querySelector('.js-kv_copy_ja')
      const kvButton = document.querySelector('.js-kv_buttons')
      const buttons = document.querySelectorAll('.js-kv_button')
      const buttonArr = Array.prototype.slice.call(buttons, 0)
      const slides = document.querySelectorAll('.js-slide')
      const slideArr = Array.prototype.slice.call(slides, 0)

      if (slideArr.length === 0) return
      const slideNo = slideArr.length - 1

      const isAct = () => {
        copyEn.classList.add('is-act')
        setTimeout(() => {
          copyJa.classList.add('is-act')
          kvButton.classList.add('is-act')
        }, 400)
      }

      const sliderInit = () => {
        slideArr[0].classList.add('is-act')
        buttonArr[0].classList.add('is-act')
        startTimer()
        pager()
        slideTimer()
        setTimeout(() => {
          isAct()
        }, 200)
      }

      const startTimer = () => {
        slideArr.forEach((elm) => {
          elm.classList.remove('is-act')
        })
        buttonArr.forEach((elm) => {
          elm.classList.remove('is-act')
        })
        slideArr[flg].classList.add('is-act')
        buttonArr[flg].classList.add('is-act')
        flg++
        if (flg > slideNo) {
          flg = 0
        }
      }

      const stopTimer = () => {
        clearInterval(setAutoTimer)
      }

      const slideTimer = () => {
        setAutoTimer = setInterval(function () {
          startTimer()
        }, 5000)
      }

      const getIndex = (element) => {
        if (!element) {
          return -1
        }
        let currentElement = element
        let index = 0
        while (currentElement.previousElementSibling) {
          index++
          currentElement = currentElement.previousElementSibling
        }
        return index
      }

      const pager = () => {
        buttonArr.forEach((elm) => {
          elm.addEventListener('click', (e) => {
            const index = getIndex(e.currentTarget)
            flg = index
            if (flg == -1) {
              flg = slideNo
            }
            if (flg > slideNo) {
              flg = 0
            }
            slideArr.forEach((elm) => {
              elm.classList.remove('is-act')
            })
            slideArr[flg].classList.add('is-act')
            buttonArr.forEach((elm) => {
              elm.classList.remove('is-act')
            })
            buttonArr[flg].classList.add('is-act')
            stopTimer()
            slideTimer()
          })
        })
      }
      sliderInit()
    })
  }
}
