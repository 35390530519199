import { UaCh } from '../foundation/UserAgent'
export class FixedSideMenu {
  constructor() {
    const mq = window.matchMedia('(min-width: 768px)').matches //スマホ判定
    if (UaCh.isMobile && !mq) return
    const elm = document.querySelector('.js-sidebar')
    if (!elm) return

    const sectionList = document.querySelectorAll('.js-anchor_section')

    const addSubMenuList = (child) => {
      let html = ''
      const ul = document.createElement('ul')
      ul.classList.add('c-common_fixed_menu__sub_list')
      child.forEach((data) => {
        const title = data.dataset.menu
        html += `<li>${title}</li>`
      })
      ul.innerHTML = html
      return ul
    }

    const addMenuList = () => {
      let html = ''
      const anchor = document.querySelector('.js-anchor')
      sectionList.forEach((data) => {
        const subSectionList = data.querySelectorAll('.js-anchor_sub_section')
        const childHtml = subSectionList.length > 0 ? addSubMenuList(subSectionList).outerHTML : ''
        const title = data.dataset.menu
        const hash = data.dataset.hash
        html += `<li class="js-anchor_item"><a href="#${hash}">${title}</a>${childHtml ? childHtml : ''}</li>`
      })
      anchor.innerHTML = html
    }

    document.addEventListener('DOMContentLoaded', () => {
      addMenuList()
    })

    // アンカーと同期
    let isAnimation = false

    const options = {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0,
    }

    // 交差した時に実行する関数
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (isAnimation) return
          activateIndex(entry.target)
        }
      })
    }

    const observer = new IntersectionObserver(callback, options)

    sectionList.forEach((section, i) => {
      observer.observe(section)
    })

    const anchorItem = document.querySelectorAll('.js-anchor_item')

    const activateIndex = (item) => {
      const currentActive = document.querySelector('.js-anchor_item.is-current')
      if (currentActive !== null) {
        currentActive.classList.remove('is-current')
      }
      const newActive = document.querySelector(`a[href='#${item.id}']`)
      if (newActive) {
        const newActiveLi = newActive.parentNode
        newActiveLi.classList.add('is-current')
      }
    }

    // const active = (item) => {
    //   item.classList.add('is-current')
    //   anchorItem.forEach((elm) => {
    //     if (elm !== item) elm.classList.remove('is-current')
    //   })
    // }

    // anchorItem.forEach((item) => {
    //   item.addEventListener('click', (event) => {
    //     const target = event.currentTarget
    //     console.log(target)
    //     isAnimation = true
    //     active(target)
    //     setTimeout(() => {
    //       isAnimation = false
    //     }, 500)
    //   })
    // })

    window.addEventListener('load', () => {
      const target = document.querySelector('.js-anchor_trg')
      if (!elm) return
      const gbcrTop = target.getBoundingClientRect().top
      const y = window.pageYOffset + gbcrTop
      window.addEventListener('scroll', (e) => {
        const top = window.scrollY
        if (top > y) {
          elm.classList.add('is-act')
        } else {
          elm.classList.remove('is-act')
        }
      })
    })
  }
}
