import Swiper from 'swiper/bundle'
import 'swiper/css/bundle'

export class BusinessSlider {
  constructor() {
    const sliders = document.querySelectorAll('.js-business_slider')
    sliders.forEach((elm) => {
      const swiper = new Swiper(elm, {
        slidesPerView: 1.4,
        spaceBetween: 15,
        breakpoints: {
          768: {
            slidesPerView: 1.8,
          },
        },
      })
    })
  }
}
