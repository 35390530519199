import Swiper from 'swiper/bundle'
import 'swiper/css/bundle'

export class WorksSlider {
  constructor() {
    const sliders = document.querySelectorAll('.js-works_slider')
    sliders.forEach((elm) => {
      const swiper = new Swiper(elm, {
        slidesPerView: 1.5,
        spaceBetween: 15,
        breakpoints: {
          768: {
            spaceBetween: 30,
            slidesPerView: 2.4,
          },
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar',
        },
        navigation: {
          nextEl: '.js-slide_arrow_next',
          prevEl: '.js-slide_arrow_prev',
        },
      })
    })
  }
}
