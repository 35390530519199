export class RightOver {
  constructor() {
    const rightOver = () => {
      const over = document.querySelectorAll('.c-right-over')
      let timer = 0
      let currentWidth = 0
      let currentLeft = 0
      const loop = () => {
        timer = window.requestAnimationFrame(loop)
        if (over.length === 0) return
        const clientWidth = document.body.clientWidth
        over.forEach((elm, i) => {
          const gbcr = elm.getBoundingClientRect()
          currentWidth = clientWidth
          currentLeft = gbcr.left
          elm.style.maxWidth = elm.style.minWidth = currentWidth - currentLeft + 'px'
        })
      }
      if (innerWidth < 1024) return
      loop()
    }

    rightOver()
  }
}
