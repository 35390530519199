export class GetJson {
  constructor() {
    document.addEventListener('DOMContentLoaded', async () => {
      const target = document.querySelector('.js-add_html')
      if (!target) return

      const getLower = async (json) => {
        let html = ''
        try {
          const response = await fetch(json)
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
          }

          const jsonData = await response.json()

          jsonData.forEach((data) => {
            html += `<li>
              ${data.url !== '' ? `<a href="${data.url}" target="_blank" class="inner">` : `<div class="inner">`}
                <div class="c-news_list__info">
                  <span class="date">${data.date}</span>
                  <span class="cat">${data.cat}</span>
                </div>
                <div class="c-news_list__title">${data.title}</div>
                <i class="c-arrow_circle">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="js-svg replaced-svg">
                    <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path fill="currentColor" d="M89.45 87.5l143.1 152c4.375 4.625 6.562 10.56 6.562 16.5c0 5.937-2.188 11.87-6.562 16.5l-143.1 152C80.33 434.1 65.14 434.5 55.52 425.4c-9.688-9.125-10.03-24.38-.9375-33.94l128.4-135.5l-128.4-135.5C45.49 110.9 45.83 95.75 55.52 86.56C65.14 77.47 80.33 77.87 89.45 87.5z"></path>
                  </svg>
                </i>
              ${data.url ? `</a>` : `</div>`}
            </li>`
          })

          target.innerHTML = html
          // target.appendChild(ul)
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error)
        }
      }

      const get = async (json, type) => {
        let html = ''
        const ul = document.createElement('ul')
        ul.classList.add('c-news_list', 'js-tab', 'p-index_news_list', type === 'news' ? 'is-show' : undefined)

        try {
          const response = await fetch(json)

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
          }

          const jsonData = await response.json()

          jsonData.slice(0, 5).forEach((data) => {
            html += `<li>
              ${data.url !== '' ? `<a href="${data.url}" target="_blank" class="inner">` : `<div class="inner">`}
                <div class="c-news_list__info">
                  <span class="date">${data.date}</span>
                  <span class="cat">${data.cat}</span>
                </div>
                <div class="c-news_list__title">${data.title}</div>
                <i class="c-arrow_circle">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="js-svg replaced-svg">
                    <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path fill="currentColor" d="M89.45 87.5l143.1 152c4.375 4.625 6.562 10.56 6.562 16.5c0 5.937-2.188 11.87-6.562 16.5l-143.1 152C80.33 434.1 65.14 434.5 55.52 425.4c-9.688-9.125-10.03-24.38-.9375-33.94l128.4-135.5l-128.4-135.5C45.49 110.9 45.83 95.75 55.52 86.56C65.14 77.47 80.33 77.87 89.45 87.5z"></path>
                  </svg>
                </i>
              ${data.url ? `</a>` : `</div>`}
            </li>`
          })

          ul.innerHTML = html
          target.appendChild(ul)
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error)
        }
      }

      if (location.pathname.includes('hongopress')) {
        await getLower('/hongopress.json')
      } else {
        await get('/news.json', 'news')
        await get('/hongopress.json', 'hongopress')
      }
    })
  }
}
